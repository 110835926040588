<template>
  <div id="login" @keydown.enter="loginIn">
    <div class="logo">
      音符跳动
      <!--          <img src="../../public/image/logo.png" alt="">-->
    </div>
    <div class="loginBox">
      <p>乐理模拟系统</p>
      <p>管理后台</p>
      <div style="margin:25px 0"><a-input type="text" v-model="account.username"  placeholder="输入账号" /></div>
      <a-input-password placeholder="输入密码" v-model="account.password" />
      <a-button class="btn" @click="loginIn">登录</a-button>
    </div>
  </div>
</template>
<script>
import { Login } from '@/libs/api'
import { setUserInfo, setToken, setPermission } from '@/libs/auth'

export default {
  name:'login',
  data(){
    return{
      account:{
        username:'',
        password:''
      }
    }
  },
  methods:{
    loginIn(){
      if( this.account.username&& this.account.password){
        Login({...this.account}).then(res=>{
          if(res){
            let { apiAuth, username, group_id, id }=res

            setPermission(group_id)
            setToken(apiAuth)

            setUserInfo({username,id})
            this.$store.commit('setUserInfo',{username,id})

            this.$store.commit('setPermission',group_id)
            if(group_id==1){
              this.$router.push('/home')
            }else{
              this.$router.push('/home/AllTask')
            }

          }
        },error=>{
          console.log(error)
        })
      }else{
        return false
      }
    }
  }
}
</script>
